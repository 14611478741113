import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerApi from '../../api/CustomerApi';

const customerApi = new CustomerApi();

export const getSignStatuses = createAsyncThunk('content/setSignStatuses', async (params) => {
	const response = await customerApi.getAllStatuses({ max_size: true, ordering: 'workflow_index' });
	return response.data;
});

const contentSlice = createSlice({
	name: 'content',
	initialState: {
		signStatuses: [],
		signActiveOrderStatuses: [],
		signProbabilityStatuses: [],
		draftStatus: {},
	},
	reducers: {
		setProbabilityStatus(state, action) {
			const currState = state;
			const currSignStatuses = currState.signStatuses;
			const allStatus = action.payload;

			const statuses = currSignStatuses
				?.filter((item) => allStatus.includes(item.name))
				?.map((s) => s.transitions)
				?.reduce((a, b) => {
					const x = a || [];
					return x.concat(b);
				}, []);

			const arrayUniqueByKey = statuses.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

			currState.signProbabilityStatuses = arrayUniqueByKey;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSignStatuses.fulfilled, (state, action) => {
			const currState = state;
			const res = action.payload?.results;
			currState.signStatuses = res;
			currState.draftStatus = res.find((r) => r.id === 1);
			currState.signActiveOrderStatuses = res.filter((r) => [2, 4, 5, 15].includes(r.id));
		});
	},
});

export const contentActions = contentSlice.actions;
export default contentSlice;
